<template>
    <div class="surface-0 overflow-hidden">
        
        <div class="grid py-4 px-4 lg:px-8 relative" id="inicio">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2">
                    Petro Servicios
                </h1>
                <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">
                    Somos una empresa nacional de capital 100% venezolano, fundada en el año 2017 por empresarios privados comprometidos con el desarrollo económico del país.
                </p>
            </div>
            <img src="/images/imagen.png" class="bottom-0" alt="inicio screen" style="right:0%;">
        </div>
        

        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="introduccion">

            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-4 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen1.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <div class="flex align-items-center justify-content-center bg-purple-200" style="width:4.2rem; height:4.2rem; border-radius: 10px;">
                        <i class="pi pi-fw pi-globe text-5xl text-purple-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Nuestra Empresa </h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">
                        Especializados y dispuestos a ofrecer efectivas soluciones técnicas a la industria petrolera nacional en el suministro de equipos y accesorios para la completación de sistemas de levantamiento artificial, con el mejor servicio de mantenimiento, restauración y reparación de estos equipos, con el fin de preservar y prolongar su vida útil; satisfaciendo así las exigencias de nuestros clientes, generando fuentes de empleos tanto directo como indirecto, apoyando la calidad de nuestra mano de obra y talento tecnológico nacional e impactando positivamente en la coyuntura económica nacional a través del ahorro de divisas y el incremento de la producción petrolera. Cabe destacar que contamos con un personal altamente capacitado y experimentado con amplios conocimientos de técnicos enfocado en proporcionar el mejor servicio y la mejor atención a nuestra industria nacional.
                    </span>
                </div>
            </div>

            <div class="grid my-8 pt-2 md:pt-8">
                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-start align-items-center">
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Misión</h2>
                    <span class="text-700 text-2xl line-height-3 mr-0 md:mr-2" style="max-width:650px;">
                        Ofrecer un servicio de calidad para así aportar las más avanzadas herramientas con la finalidad de lograr la satisfacción completa de nuestros clientes; siempre de la mano con nuestro capacitado talento humano, el cual nos hace alcanzar la excelencia en el servicio obteniendo con esto soluciones efectivas.
                    </span>
                </div>
                <div class="flex justify-content-center col-12 lg:col-4 bg-yellow-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen2.png" class="w-11" alt="mockup mobile">
                </div>
            </div>
            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-4 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen3.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Visión</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">
                        Posicionarnos como empresa líder en servicios para la industria petrolera a nivel nacional por su calidad de servicio.
                    </span>
                </div>
            </div>

        </div>

        <div class="py-4 px-4 mx-0 mt-8 lg:mx-8" id="ubicacion">
            <Ubicacion/>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	props: {
		navegar: {
			type: String,
			default: null
		}
	},
	components: {
		Ubicacion: defineAsyncComponent(() => import('../components/Ubicacion.vue')),
	},
	data() {
		return {
			d_navegar: this.navegar,
            temporal: null,
		}
	},
    watch: {
		navegar(newValue) {
			this.smoothScroll(newValue);
		}
	},
    methods: {
        smoothScroll(id){
            alert(id);

            const ini = document.querySelector('#inicio');
			const el = document.querySelector('#'+id);
			var y = el.getBoundingClientRect().top - 70;           
            const med = ini.getBoundingClientRect().top - 70;
            //if(this.temporal != 'inicio' && id != 'inicio'){
                y = y - med;
            //}
			window.scrollTo({top: y, behavior: 'smooth'});
            this.temporal = id;         
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style>
#inicio{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #8b8b89 0%, #afb1b3 100%);
    height:700px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    #inicio{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px){
    #inicio > img {
        position: absolute;
    }

    #inicio > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #inicio {
        height: 600px;
    }

    #inicio > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #inicio > div {
        width: 100%;
    }

    #inicio > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>