<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="icono.png" />
		</router-link>

        <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                <li>
                    <a @click="Navegacion('inicio')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Inicio</span>
                    </a>
                </li>
                <li>
                    <a @click="Navegacion('features')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Servicios y Equipo</span>
                    </a>
                </li>
                <li>
                    <a @click="Navegacion('highlight')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Productos</span>
                    </a>
                </li>
                <li>
                    <a @click="Navegacion('ubicacion')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                        <span>Ubicación</span>
                    </a>
                </li>
            </ul>
        </div>

		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li class="mr-2 mb-2">
                <i class="pi pi-at"> </i>
                <span>info@petroservicios7.com.ve</span>
			</li>
			<li class="mr-2 mb-2">
                <i class="pi pi-whatsapp">  </i>
                <span>(0283)514.84.01</span>
			</li>
            <!--
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-at"> info@petroservicios7.com.ve</i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					Info@petroservicios7.com.ve

				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					Info@petroservicios7.com.ve

				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
            -->
		</ul>

	</div>
</template>

<script>
export default {
    methods: {
        Navegacion(id){
            this.$store.state.view = id;
            this.$emit('menu-toggle', event);
        }, 

        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>